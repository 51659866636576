<template>
  <div id="app">
    <CameraComponent />
  </div>
</template>

<script>
import CameraComponent from './components/TestComponent.vue';

export default {
  name: 'App',
  components: {
    CameraComponent
  }
};
</script>