<template>
  <div>
    <div v-if="isLoading" class="progress-container">
      <div class="progress-bar" :style="{ width: loadingProgress + '%' }"></div>
    </div>
    <video ref="video" autoplay playsinline style="display:none; transform: scaleX(-1);"></video>
    <canvas ref="output3D" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;"></canvas>
    <canvas ref="output2D" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;"></canvas>
  </div>
</template>

<script>
import { ImageSegmenter, FilesetResolver } from '@mediapipe/tasks-vision';
// import { Camera } from '@mediapipe/camera_utils';
import * as THREE from 'three';
import { markRaw } from 'vue';

export default {
  name: 'BackgroundSegmentation',
  data() {
    return {
      processor: null,
      runningMode: "VIDEO",
      video: null,
      net: null,
      outputCanvas3D: null,
      outputCanvas2D: null,
      renderer: null,
      scene: null,
      camera: null,
      personTexture: null,
      particleSystem: null,
      isLoading: true,
      loadingProgress: 0,
      lastWebcamTime: -1,
      mirror: null,
      shadow: null,
    };
  },
  async mounted() {
    this.loadingProgress = 20;
    await this.createImageSegmenter();
    console.log("Image Segmenter initialized:", this.processor);
    this.loadingProgress = 70;
    await this.requestPermissions();
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    if (this.video && this.video.srcObject) {
      const tracks = this.video.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
  },
  methods: {
    async createImageSegmenter() {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.2/wasm"
      );
      this.loadingProgress = 50;

      const options = {
        baseOptions: {
          modelAssetPath:
            "https://storage.googleapis.com/mediapipe-models/image_segmenter/selfie_segmenter/float16/latest/selfie_segmenter.tflite",
          delegate: "GPU"
        },
        outputCategoryMask: true,
        outputConfidenceMasks: false,
        runningMode: "VIDEO"
      };

      this.processor = await ImageSegmenter.createFromOptions(vision, options);
    },
    async requestPermissions() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
        this.video = this.$refs.video;
        this.video.srcObject = stream;
        
        this.video.play();
        this.video.addEventListener("loadeddata", this.predictWebcam);
        // await new Promise(resolve => this.video.onloadedmetadata = this.predictWebcam);

        await this.initThreeJS();
        this.loadingProgress = 100;
        this.isLoading = false;
        // await this.setupCamera();
      } catch (err) {
        console.error('Camera access denied or error occurred:', err);
        alert('Camera access is required for this application. Please check your permissions.');
      }
    },
    async predictWebcam() {
      if (!this.processor) {
        console.error("Image Segmenter is not initialized.");
        return;
      }

      if (!this.video || !this.video.readyState >= 2) {
          console.error("Video is not ready.");
          requestAnimationFrame(this.predictWebcam);
          return;
      }

      if (this.video.currentTime === this.lastWebcamTime) {
        requestAnimationFrame(this.predictWebcam);
        return;
      }
      this.lastWebcamTime = this.video.currentTime;

      const startTimeMs = performance.now();
      await this.processor.segmentForVideo(this.video, startTimeMs, this.callbackForVideo);

      requestAnimationFrame(this.predictWebcam);
    },
    callbackForVideo(result) {
        this.outputCanvas2D = this.$refs.output2D;
        const ctx2D = this.outputCanvas2D.getContext('2d');
        
        const canvasHeight = window.innerHeight;
        const videoAspectRatio = this.video.videoWidth / this.video.videoHeight;
        
        const canvasWidth = canvasHeight * videoAspectRatio;

        this.outputCanvas2D.width = canvasWidth;
        this.outputCanvas2D.height = canvasHeight;
        this.outputCanvas2D.style.width = `${canvasWidth}px`;
        this.outputCanvas2D.style.height = `${canvasHeight}px`;

        ctx2D.save(); 
        ctx2D.scale(-1, 1);  
        ctx2D.translate(-this.outputCanvas2D.width, 0);
        ctx2D.clearRect(0, 0, canvasWidth, canvasHeight);

        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = this.video.videoWidth;
        tempCanvas.height = this.video.videoHeight;
        const tempCtx = tempCanvas.getContext('2d');

        let imageData = tempCtx.getImageData(0, 0, this.video.videoWidth, this.video.videoHeight).data;
        const mask = result.categoryMask.getAsFloat32Array();

        this.generateReflectionMap(mask);

        let j = 0;

        for (let i = 0; i < mask.length; ++i) {
            const maskVal = Math.round((1.0 - mask[i]) * 255.0);
            imageData[j + 3] = maskVal;
            j += 4;
        }
        const uint8Array = new Uint8ClampedArray(imageData.buffer);
        const dataNew = new ImageData(
            uint8Array,
            this.video.videoWidth,
            this.video.videoHeight
        );
        tempCtx.putImageData(dataNew, 0, 0);

        ctx2D.clearRect(0, 0, canvasWidth, canvasHeight);
        const offsetX = (this.outputCanvas2D.width - canvasWidth) / 2;

        ctx2D.drawImage(this.video, offsetX, 0, canvasWidth, canvasHeight);
        ctx2D.globalCompositeOperation = 'destination-in';
        ctx2D.drawImage(tempCanvas, 0, 0, this.video.videoWidth, this.video.videoHeight, offsetX, 0, canvasWidth, canvasHeight);
        ctx2D.globalCompositeOperation = 'source-over';

        // this.updateMirrorsWithReflection(tempCanvas);
    },
    generateReflectionMap(mask) {
        const reflectionCanvas = document.createElement('canvas');
        reflectionCanvas.width = this.video.videoWidth;
        reflectionCanvas.height = this.video.videoHeight;
        const ctx = reflectionCanvas.getContext('2d');

        // Clear the canvas to make it fully transparent
        ctx.save(); // Save the context state
        ctx.scale(-1, 1); // Flip horizontally
        ctx.translate(-reflectionCanvas.width, 0); // Adjust the position after flip
        ctx.clearRect(0, 0, reflectionCanvas.width, reflectionCanvas.height);

        // Get the mask data
        const maskData = mask;

        // Create a new canvas to process the mask and video data
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = this.video.videoWidth;
        tempCanvas.height = this.video.videoHeight;
        const tempCtx = tempCanvas.getContext('2d');

        // Draw the video frame onto the temporary canvas
        tempCtx.drawImage(this.video, 0, 0, tempCanvas.width, tempCanvas.height);

        // Get the image data from the temporary canvas
        const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
        const data = imageData.data;

        // Apply the segmentation mask to retain only the person
        for (let i = 0; i < maskData.length; i++) {
            const maskVal = maskData[i]; // Get the mask value (0.0 to 1.0)

            if (maskVal > 0) {
                // If the mask indicates background, make the pixel fully transparent
                data[i * 4 + 3] = 0; // Set alpha channel to 0 (fully transparent)
            }
            // No need to modify the alpha channel of person pixels
        }

        // Put the processed image data back onto the temporary canvas
        tempCtx.putImageData(imageData, 0, 0);

        // Apply a blur effect (if available)
        ctx.filter = 'blur(5px)'; // Adjust the blur radius as needed
        ctx.globalAlpha = 0.4;

        // Draw the processed image (only the person) onto the reflection canvas, scaled slightly larger
        const scaleFactor = 1.4; // Adjust this scale factor as needed
        const scaledWidth = tempCanvas.width * scaleFactor;
        const scaledHeight = tempCanvas.height * scaleFactor;
        const offsetX = (reflectionCanvas.width - scaledWidth) / 2;
        const offsetY = (reflectionCanvas.height - scaledHeight) / 2;

        ctx.drawImage(tempCanvas, offsetX, offsetY, scaledWidth, scaledHeight);

        // Reset the filter to avoid affecting other drawings
        ctx.restore();
        ctx.filter = 'none';
        ctx.globalAlpha = 1.0;
        

        // Use this reflection canvas to update the mirrors
        this.updateMirrorsWithReflection(reflectionCanvas);
    },
    updateMirrorsWithReflection(reflectionCanvas) {
      const reflectionTexture = new THREE.CanvasTexture(reflectionCanvas);
      reflectionTexture.minFilter = THREE.LinearFilter; // Optional: Improve texture filtering
      reflectionTexture.magFilter = THREE.LinearFilter;
      this.scene.traverse((child) => {
          if (child.isMesh && child.material && child.material.uniforms) {
              // Update the overlayTexture uniform with the new reflection texture
              child.material.uniforms.overlayTexture.value = reflectionTexture;
              child.material.needsUpdate = true;
          }
      });
    },
    async initThreeJS() {
      this.outputCanvas3D = this.$refs.output3D;
      this.renderer = new THREE.WebGLRenderer({ canvas: this.outputCanvas3D, alpha: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setClearColor(0x000000, 1);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Soft shadows
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camera.position.z = 120;
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      const light = markRaw(new THREE.PointLight(0xffffff, 1.4, 1000));
      light.position.set(0, 0, 100);
      this.scene.add(light);
      // this.createBigMirror();
      this.createMosaicMirror();
      // this.createMirror();
      this.animate();
    },
    createBigMirror() {
      const textureLoader = markRaw(new THREE.TextureLoader());
      const mirrorTexture = textureLoader.load('immagine.jpeg');
      const fov = this.camera.fov * (Math.PI / 180); 
      const height = 2 * Math.tan(fov / 2) * this.camera.position.z; 
      const width = height * this.camera.aspect; 

      const geometry = new THREE.PlaneGeometry(width, height);
      mirrorTexture.wrapS = mirrorTexture.wrapT = THREE.RepeatWrapping; 
      mirrorTexture.repeat.set(1, 1); 
      mirrorTexture.offset.set(0, 0);
      // const mirrorMaterial = markRaw(new THREE.MeshBasicMaterial({
      //   map: mirrorTexture,    
      //   side: THREE.DoubleSide, 
      //   transparent: true,         
      //   opacity: 0.5               
      // }));
      const material = new THREE.ShaderMaterial({
        uniforms: {
          baseTexture: { type: 't', value: mirrorTexture },
          overlayTexture: { type: 't', value: mirrorTexture },
          opacity: { type: 'f', value: 1.0 }, // Optional: Control overall opacity
        },
        vertexShader: `
          varying vec2 vUv;
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform sampler2D baseTexture;
          uniform sampler2D overlayTexture;
          varying vec2 vUv;
          
          void main() {
            vec4 baseColor = texture2D(baseTexture, vUv);
            vec4 overlayColor = texture2D(overlayTexture, vUv);

            // Simple blend: overlay on top of base, preserving transparency
            vec4 finalColor = mix(baseColor, overlayColor, overlayColor.a);
            
            gl_FragColor = finalColor;
          }
        `,
        transparent: true
      });
      this.mirror = markRaw(new THREE.Mesh(geometry, material));
      this.mirror.position.set(0, 0, -1); 
      this.scene.add(this.mirror);

      // this.shadow = markRaw(new THREE.Mesh(geometry, mirrorMaterial));
      // this.scene.add(this.shadow);
    },
    createMosaicMirror() {
      const textureLoader = markRaw(new THREE.TextureLoader());
      const mirrorTexture = textureLoader.load('immagine.jpeg');
      mirrorTexture.wrapS = THREE.ClampToEdgeWrapping;
      mirrorTexture.wrapT = THREE.ClampToEdgeWrapping;

      const fov = this.camera.fov * (Math.PI / 180); 
      const height = 2 * Math.tan(fov / 2) * this.camera.position.z; 
      const width = height * this.camera.aspect; 

      const rows = 30; // Adjust to increase/decrease mosaic granularity
      const cols = 30;
      const tileWidth = width / cols;
      const tileHeight = height / rows;

      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          const geometry = markRaw(new THREE.PlaneGeometry(tileWidth, tileHeight));
          
          // Adjust UV mapping for mosaic effect
          const uv = geometry.attributes.uv;
          uv.array[0] = j / cols;
          uv.array[1] = i / rows;
          uv.array[2] = (j + 1) / cols;
          uv.array[3] = i / rows;
          uv.array[4] = j / cols;
          uv.array[5] = (i + 1) / rows;
          uv.array[6] = (j + 1) / cols;
          uv.array[7] = (i + 1) / rows;
          uv.needsUpdate = true;

          const material = markRaw(new THREE.ShaderMaterial({
            uniforms: {
              baseTexture: { type: 't', value: mirrorTexture },
              overlayTexture: { type: 't', value: mirrorTexture },
              opacity: { type: 'f', value: 1.0 },
            },
            vertexShader: `
              varying vec2 vUv;
              void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              }
            `,
            fragmentShader: `
              uniform sampler2D baseTexture;
              uniform sampler2D overlayTexture;
              varying vec2 vUv;
              
              void main() {
                vec4 baseColor = texture2D(baseTexture, vUv);
                vec4 overlayColor = texture2D(overlayTexture, vUv);

                vec4 finalColor = mix(baseColor, overlayColor, overlayColor.a);
                
                gl_FragColor = finalColor;
              }
            `,
            transparent: true
          }));

          const tileMesh = markRaw(new THREE.Mesh(geometry, material));

          tileMesh.position.x = j * tileWidth - width / 2 + tileWidth / 2;
          tileMesh.position.y = i * tileHeight - height / 2 + tileHeight / 2;

          this.scene.add(tileMesh);
        }
      }
    },
    createMirror() {
      // const textureLoader = new THREE.TextureLoader();
      // const texture = textureLoader.load('immagine.jpeg');

      // const vertexShader = `
      //   varying vec2 vUv;
      //   void main() {
      //     vUv = uv;
      //     gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      //   }
      // `;

      // const fragmentShader = `
      //   uniform float time;
      //   uniform sampler2D uTexture;
      //   varying vec2 vUv;

      //   void main() {
      //     vec4 texColor = texture(uTexture, vUv); // Using the renamed uniform
      //     vec3 color = texColor.rgb * (0.5 + 0.5 * sin(time + vUv.xyx * 3.0));
      //     gl_FragColor = vec4(color, texColor.a);
      //   }
      // `;

      // const shaderMaterial = new THREE.ShaderMaterial({
      //   uniforms: {
      //     time: { value: 0.0 },
      //     uTexture: { value: texture },
      //   },
      //   vertexShader,
      //   fragmentShader,
      // });


      const rows = 50;
      const cols = Math.round(rows * (window.innerWidth / window.innerHeight));
      const spacing = 6.3;
      const geometry = markRaw(new THREE.PlaneGeometry(6, 6));
      // const material = markRaw(new THREE.MeshBasicMaterial({ color: 0xd8b04a }));

      // const mesh = markRaw(new THREE.Mesh(geometry, shaderMaterial));

      // const material = markRaw(new THREE.MeshBasicMaterial({
      //   map: texture,
      //   side: THREE.DoubleSide, 
      // }));

      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,  
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0.5,  
          });

          const mesh = markRaw(new THREE.Mesh(geometry, material));
          mesh.position.x = (j - cols / 2) * spacing;
          mesh.position.y = (i - rows / 2) * spacing;
          mesh.rotation.x = Math.random() * 0.1 - 0.05;
          mesh.rotation.y = Math.random() * 0.1 - 0.05;
          mesh.rotation.z = Math.random() * 0.1 - 0.05;

          if (mesh.geometry.attributes.uv) {
            const uvs = mesh.geometry.attributes.uv.array;

            for (let k = 0; k < uvs.length; k += 2) {
              uvs[k] = (j + uvs[k]) / cols;
              uvs[k + 1] = (i + (1 - uvs[k + 1])) / rows;
            }

            mesh.geometry.attributes.uv.needsUpdate = true;
          }

          this.scene.add(mesh);

          mesh.userData = {
            speed: Math.random() * 1.5,
            angle: Math.random() * Math.PI * 2,
            isMirror: true
          };
        }
      }
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      const aspectRatio = window.innerWidth / window.innerHeight;

      this.mirror.geometry.dispose(); 
      this.mirror.geometry = new THREE.PlaneGeometry(aspectRatio * 2, 2);

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.outputCanvas2D.width = window.innerWidth;
      this.outputCanvas2D.height = window.innerHeight;
    },
  }
}
</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
}
canvas {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.progress-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: #f3f3f3;
  border-radius: 25px;
  overflow: hidden;
  z-index: 1000;
}
.progress-bar {
  height: 20px;
  background-color: #4caf50;
  border-radius: 25px;
  transition: width 0.3s ease;
}
</style>
